import { faClock, faUser } from '@fortawesome/free-regular-svg-icons';
import { faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, graphql } from 'gatsby';
import React from 'react';
import Container from '../components/container/container';
import Layout from '../components/layout/layout';
import PageHeader from '../components/paragraphs/page-header/page-header';
import SEO from '../components/seo';
import './recepten.scss';

export default function Cases({ location, data }) {
  const langCode =
    location.pathname.split('/').filter(e => e !== '')[0] === 'en'
      ? 'en'
      : 'nl';
  return (
    <Layout location={location}>
      <SEO title="Recepten" lang={langCode} />
      <PageHeader
        title={'Recepten'}
        align={'Gecentreerd'}
        colors={'Groen'}
        breadcrumbs={[
          { url: '/', title: 'Home' },
          { url: '/inspiratie', title: 'Inspiratie' }
        ]}
        langCode={langCode}
      ></PageHeader>
      <Container style={{ paddingTop: '5rem', paddingBottom: '5rem' }}>
        <div className="block__recipes-overview">
          {data.allNodeRecept.nodes.reverse().map(recipe => {
            return (
              <Link to={recipe.path.alias}>
                <div className="recipe">
                  <div className="image-container">
                    <img
                      src={
                        recipe.relationships.field_afbeeldingen[0]
                          .field_media_image.imageDerivatives.links
                          .blog_overview.href
                      }
                      alt=""
                    />
                    <div className="info">
                      <p>
                        <FontAwesomeIcon icon={faClock} />{' '}
                        {recipe.relationships.field_duration.name}
                      </p>
                      <p>
                        <FontAwesomeIcon icon={faUser} />{' '}
                        {recipe.relationships.field_portion.name}
                      </p>
                      <p>
                        {recipe.relationships.field_recipe_tags.length > 0 && (
                          <>
                            <FontAwesomeIcon icon={faTags} />{' '}
                            {recipe.relationships.field_recipe_tags[0]?.name}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                  <h4>{recipe.title}</h4>
                </div>
              </Link>
            );
          })}
        </div>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query AllRecipes {
    allNodeRecept {
      nodes {
        id
        title
        path {
          alias
        }
        relationships {
          field_afbeeldingen {
            field_media_image {
              imageDerivatives {
                links {
                  blog_overview {
                    href
                  }
                }
              }
            }
          }
          field_duration {
            name
            id
          }
          field_portion {
            name
            id
          }
          field_recipe_steps {
            relationships {
              field_media {
                field_media_image {
                  imageDerivatives {
                    links {
                      story_paragraph {
                        href
                      }
                    }
                  }
                }
              }
            }
            field_recipe_step_description
          }
          field_recipe_tags {
            name
            id
          }
          field_ingredienten {
            field_title {
              processed
            }
            relationships {
              field_ingredienten {
                field_hoeveelheid
                relationships {
                  field_ingredient {
                    title
                    id
                    field_photo {
                      imageDerivatives {
                        links {
                          story_paragraph {
                            href
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
